.header {
  background-color: #fff;
  padding: 0;
  box-shadow: 0 1px 4px rgba(0,21,41,0.08);
  overflow: hidden;
  display: flex;
  align-items: center;
}
.header .ant-avatar {
  width: 20px;
  height: 20px;
}
.header .projectSelector {
  display: flex;
  justify-content: center;
  place-items: center;
}
.header .trigger {
  padding: 0 24px;
  font-size: 20px;
  cursor: pointer;
  line-height: 64px;
  transition: all 0.3s;
}
.header .trigger.fold {
  transform: rotate(180deg);
}
.header .trigger:hover {
  background-color: #d1e3fa;
}
.header .rightBox {
  flex: auto;
  display: flex;
  justify-content: flex-end;
}
.header .rightBox .userhead {
  padding: 0 20px;
  margin-right: 4px;
  cursor: pointer;
  font-size: 20px;
}
.header .rightBox .userhead .username {
  font-size: 14px;
  margin-left: 4px;
}
.header .rightBox .userhead:hover {
  background-color: #d1e3fa;
}
.header .full {
  padding: 0 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.header .full .ant-badge {
  display: inline !important;
}
.header .full .ant-badge .ant-badge-count {
  top: -15px;
}
.header .full .icon {
  font-size: 20px;
  padding: 4px;
}
.header .full:hover {
  background-color: #d1e3fa;
}
.header .full a {
  display: block;
  width: 100%;
  height: 100%;
}
.menu .anticon {
  margin-right: 8px;
}
.menu .ant-dropdown-menu-item {
  width: 160px;
}
.headerPopover .ant-popover-inner-content {
  padding: 0;
}
.headerTabs {
  max-width: 400px;
}
.headerTabs .ant-tabs-bar {
  padding: 12px 16px 0 16px;
}
.headerTabs .ant-tabs-tab {
  margin: 0 32px 0 0 !important;
}
.headerTabs .ant-tabs-tab:last-child {
  margin-right: 0 !important;
}
.headerTabs .ant-tabs-content {
  min-height: 200px;
}
.headerTabs .link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px;
  transition: all 0.3s;
}
.headerTabs .link + .link {
  border-top: solid 1px #f0f0f0;
}
.headerTabs .link:hover {
  background-color: #d1e3fa;
}
.headerTabs .clear {
  width: 100%;
  color: #888;
  transition: all 0.3s;
  cursor: pointer;
  border: none;
  border-top: solid 1px #f0f0f0;
}
.headerTabs .clear:hover {
  color: red;
  background-color: #d1e3fa;
}

.header .underDevelopmentWarn div{
  color: #1a68b1
}

.header .avatarWithText .ant-avatar-string{
  transform: scale(1) translateX(-50%) translateY(-20%) !important;
}
