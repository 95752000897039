.page-basic {
  width: 100%;
  min-height: 100vh;
}
.page-basic .content {
  margin: 0 16px 16px 16px;
  padding: 16px;
  background: white;
  height: 100%;
  min-height: 280px;
}

::-webkit-scrollbar {
  /* width: 17px;
  height: 17px;
  margin-top: 20px; */
}

::-webkit-scrollbar-track {
  /* background-color: #e6e6e638; */
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  /* border: 6px solid transparent; */
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
