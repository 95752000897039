.sider {
  box-shadow: 2px 0 6px rgba(0,21,41,0.35);
  min-height: 100vh;
  z-index: 5;
}
.sider .menuLogo {
  height: 64px;
  background-color: #001529;
}
.sider .menuLogo.hide a {
  display: flex;
  justify-content: center;
  place-items: center;
}
.sider .menuLogo.hide a div {
  overflow: hidden;
  opacity: 0;
  width: 0;
}
.sider .menuLogo a {
  display: flex;
  justify-content: center;
  place-items: flex-end;
  height: 100%;
}
.sider .menuLogo a img {
  width: 55%;
  flex: none;
}
.sider .menuLogo a div {
  color: #fff;
  width: 100%;
  font-size: 24px;
  padding-left: 10px;
  white-space: nowrap;
  transition: all 0.3s;
}
