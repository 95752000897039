.page-stock-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-stock-edit .title-bar {
  padding-bottom: 1vh;
  border-bottom: solid 1px #d9d9d9;
  display: flex;
  place-items: center;
  justify-content: space-between;
}

.page-stock-edit .title-bar .ant-tag {
  margin: unset;
}

.page-stock-edit .page-stock-edit-content {
  padding: 1.5vh 0;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.page-stock-edit .page-stock-edit-content .actions {
  display: flex;
  justify-content: flex-end;
  gap: 1vw;
  margin-bottom: 1.5vh;
  /* padding-right: 1.5vw; */
}

.page-stock-edit .title {
  font-weight: 500;
  font-size: 18px;
}

.page-stock-edit .slots {
  width: 100%;
  color: grey;
  flex: 1 2 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 2px;
}

.page-stock-edit .slotsContainer {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  height: 100%;
  /* border-top: 1px lightgray dashed; */
  padding: 1% 1% 0 1%;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  margin: unset;
  margin-bottom: 2vh;
  min-height: 200px;
}

.page-stock-edit .slotsContainer .lastUpdatetimeTag {
  display: flex;
  justify-content: space-between;
  place-items: center;
  gap: 1vw;
}

.page-stock-edit .slots {
  display: flex;
  gap: 2vh;
  row-gap: 2vh;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
  flex: 1 2 auto;
  width: 100%;
  padding: 2vh 3px;
}

.page-stock-edit .slotsContainer .slot {
  padding: 1%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  place-items: center;
  flex-flow: column;
  text-align: center;
  min-width: 150px;
  width: 15.5%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.page-stock-edit .slotsContainer .slot:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.page-stock-edit .slotsContainer .slot .slotId {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 0.5em;
  background-color: grey;
  color: white;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 4px;
}
.page-stock-edit .slotsContainer .slot .slotActions {
    position: absolute;
    right: 2%;
    top: 2%;
    width: 1.5em;
    flex-direction: column;
    row-gap: 1vh;
}

.page-stock-edit .slotsContainer .slot .slotActions .slotButton {
  height: 1.5em;
  width: 100%;
  font-size: 1em;
  margin-bottom: 10%;
  background-color: rgb(237, 237, 237);
  border-radius: 21%;
  color: rgb(154, 154, 154);
  display: flex;
  justify-content: center;
  text-align: center;
  place-items: center;
}

.page-stock-edit .slotsContainer .slot .slotActions .slotButton:hover {
  color: white;
  /* color: white; */
  background-color: rgb(191, 191, 191);
}

.page-stock-edit .slotsContainer .slot input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-stock-edit .slotsContainer .slot img {
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid lightgrey;
  width: 7em;
  height: 7em;
  padding: 2%;
  cursor: pointer;
}

.page-stock-edit .slotsContainer .slot .placeholder {
  border-radius: 50%;
  border: 1px solid lightgrey;
  width: 7em;
  height: 7em;
  cursor: pointer;
}

.page-stock-edit .slotsContainer .slot .ant-input {
  text-align: right;
}
.page-stock-edit .slotsContainer .slot .ant-input-suffix {
  color: grey;
}

.page-stock-edit .slotsContainer .slot .changes {
  color: white;
  font-size: 1.3em;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 0 0.5em;
  font-weight: bold;
}

.page-stock-edit .slotsContainer .slot .changes.noChange {
  background-color: lightgrey;
}
.page-stock-edit .slotsContainer .slot .changes.positiveChange {
  background-color: rgb(88, 195, 95);
}
.page-stock-edit .slotsContainer .slot .changes.negativeChange {
  background-color: rgb(207, 99, 99);
}

.page-stock-edit .ant-spin-blur .slotsContainer {
  background-color: #ebeaeaca;
  border: 1px solid #d9d9d9;
  /* border-radius: 5px; */
  /* filter: grayscale(20%) */
}
.page-stock-edit .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding-right: 12px;
}
.page-stock-edit .statusText {
  justify-content: center;
  display: flex;
  gap: 1vw;
  font-size: 1em;
  font-weight: 500;
}

.page-stock-edit .subtitle {
  font-weight: bold;
  margin-bottom: 1vh;
}

.page-stock-edit .slotsContainer .slot.warning {
  border: 1px solid #d9a74b;
  background-color: rgb(255, 251, 237);
}

.page-stock-edit .slotsContainer .slot.warning .ant-input-affix-wrapper {
  border-color: #d9a74b;
}

.page-stock-edit .slotsContainer .slot.warning .slotId {
  background-color: #ce9e46;
}

.component-last-stock-edit-result {
  width: 100%;
  /* border: 1px solid rgb(227, 227, 227); */
  margin-bottom: 2.5vh;
  /* border-radius: 5px; */
  position: relative;
}
.component-last-stock-edit-result .statusHeader {
  display: inline-flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-right: 1vw;
}

.page-stock-edit-content .ant-spin-text {
  font-size: 1.3em;
  font-weight: 500;
}

.component-last-stock-edit-result .statusBar {
  height: 100%;
  position: absolute;
  left: 0;
  width: 1%;
  background-color: rgb(227, 227, 227);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.component-last-stock-edit-result .ant-collapse-header {
  display: flex;
  justify-content: space-between;
}
.component-last-stock-edit-result.success .ant-collapse-header * {
  color: #4d6247;
}

.component-last-stock-edit-result.success .ant-collapse-header {
  background-color: #dcf2d7;
}

.component-last-stock-edit-result.success .ant-collapse,
.component-last-stock-edit-result.success .ant-collapse *:not(.ant-timeline-item-head, .ant-timeline-item-tail) {
  border-color: #abc3a6;
}

.component-last-stock-edit-result.error .ant-collapse-header * {
  color: #a82f34;
}

.component-last-stock-edit-result.error .ant-collapse-header {
  background-color: #eec8c4;
}

.component-last-stock-edit-result.error .ant-collapse,
.component-last-stock-edit-result.error .ant-collapse *:not(.ant-timeline-item-head, .ant-timeline-item-tail) {
  border-color: #c9aca7;
}

.component-last-stock-edit-result .ant-timeline-item-content {
  white-space: break-spaces;
}
.component-last-stock-edit-result .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: unset;
}

.component-last-stock-edit-result .ant-timeline-item:last-child {
  padding: 0;
}
.component-last-stock-edit-result .timestamp {
  background-color: rgb(227, 227, 227);
  padding: 0.1vh 0.4vw;
  border-radius: 3px;
  font-size: 0.95em;
  color: rgb(79, 79, 79);
}
.component-last-stock-edit-result .timeline .timestamp {
  background-color: rgb(244, 244, 244);
}

.component-last-stock-edit-result .ant-collapse-content > .ant-collapse-content-box {
  padding: 20px 14px 16px 14px;
}

.component-description-info {
  margin-bottom: 2vh;
  width: 100%;
  flex: 0 1 auto;
  border: 1px solid rgb(237, 237, 237);
  background: #efefef;
  min-height: 12vh;
}

.component-description-info * {
  color: #848383;
}

.component-description-warning {
  margin-bottom: 2vh;
  width: 100%;
  flex: 0 1 auto;
  border: 1px solid #b9a588;
  background: #fff9c9;
}

.component-description-warning * {
  color: #d3912c;
}
