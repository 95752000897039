.error-boundary {
  text-align: center;
  padding: 50px;
  font-size: 14px;
  position: relative;
  margin: 0 auto;
  color: #aaa;
}
.error-boundary .error-icon {
  font-size: 60px;
  margin-bottom: 20px;
}
