.loading {
  text-align: center;
  padding: 50px;
  font-size: 14px;
  position: relative;
  margin: 0 auto;
  color: #888;
}
.loading img {
  margin-bottom: 20px;
}
