/* Added according to https://ant.design/docs/react/use-with-create-react-app */
@import '~antd/dist/antd.css';

/* ------------------------------------------- */
/* Content from '@/assets/styles/default.less' */
/* ------------------------------------------- */
/* 全局配置 */
* {
	-webkit-overflow-scrolling: touch; /* 允许独立滚动区域，解决IOS上的非body元素滚动条滚动时卡顿 */
	-webkit-tap-highlight-color: transparent; /* 覆盖IOS上用户点击连接时的默认高亮颜色 */
	outline: none;
}
body {
	min-width: 1200px;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
/* 禁止换行,末尾省略 */
.all_nowarp {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
/* 强制换行 */
.all_warp {
	word-break: break-all;
	word-wrap: break-word;
}
.all_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* ------------------------------------------ */
/* Content from '@/assets/styles/global.less' */
/* ------------------------------------------ */
/* 一些全局都用到的样式 */

/* table基础样式 */
.diy-table .control-btn {
	font-size: 14px;
	cursor: pointer;
}
.diy-table .control-btn.green {
	color: #00a854;
}
.diy-table .control-btn.red {
	color: #ff3333;
}
.diy-table .control-btn.blue {
	color: #0066cc;
}
.diy-table .ant-pagination {
	margin-right: 20px;
}
.diy-table td {
	max-width: 300px;
	min-width: 50px;
}

/* 全局search基础样式 */
.g-search {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}
.g-search .ant-divider {
	margin: 0 20px;
}
.g-search .search-func {
	display: flex;
}
.g-search .search-ul {
	display: flex;
}
.g-search .search-ul > li {
	padding: 2px;
	margin-right: 4px;
}
.g-search .search-ul > li > span:first-child {
	min-width: 90px;
	text-align: right;
	display: inline-block;
	margin-right: 10px;
}
.g-search .search-ul.btns {
	border-top: solid 1px #f0f0f0;
	padding-top: 4px;
}
.ant-tooltip {
	min-width: 30px;
}

.advancedSearchContent .advancedSearchTitle .ant-radio-button-wrapper{
    line-height: 1.4;
}

.advancedSearchContent .advancedSearchTitle .ant-radio-group {
    margin: 0 0.5vw
}


.advancedSearchContent .searchItem{
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    margin-top: 1.5vh;
}
.advancedSearchContent .searchItemTitle{
    /* border-bottom: 1px solid #f0f0f0; */
    /* padding-left: 1.5vw; */
    padding: 0.5vh 0 1vh  24px;
    background-color: #f7f7f7;
    font-weight: 500;
}

.advancedSearchContent .searchItemContent {
    width: 600px;
    padding: 2%;
    /* background-color: #f7f7f7; */
    display: flex;
    justify-content: center;
    place-items: center;

}
.advancedSearchContent .searchItemColumn {
    width: 30%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    place-items: flex-start;
    padding: 0 2%
}
.advancedSearchContent .dateRangeItemColumn {
    width: 90%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    place-items: flex-start;
    padding-right: 2%
}
.advancedSearchContent .searchItemColumn .ant-row{
    width: 100%;
}
.advancedSearchContent .dateRangeItemColumn .ant-row{
    width: 100%;
}
.advancedSearchContent .searchItemColumnLast {
    width: 10%;
    display: flex;
    flex-flow: column;
    place-items: center;
}
.advancedSearchContent .ant-radio-button-wrapper {
    height: 23px;
}
.advancedSearchContent .searchItemColumn .ant-select{
    width: 100%;
}

.advancedSearchContent .dateRangeItemColumn .ant-picker {
    width: 100%;
 }

 .emptyTable .ant-table-tbody > tr > td{
	 border-bottom: 0;
 }

 .table-show-more .pageNumber {
    color: grey;
    margin: 0 1vw;   
}
.ant-back-top {
    right: 50px
}

.ant-table-tbody > tr:last-child > td{
    border-bottom: 0 !important;
}
