.bread {
    padding: 16px;
    display: flex;
    align-items: center;
}
.bread .icon {
    flex: none;
    color: #22cc22;
    margin-right: 8px;
}
