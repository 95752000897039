.footer {
  text-align: center;
  padding: 0 16px 16px 16px;
  flex: none;
}
.footer a:hover {
  text-decoration: underline;
}
.footer.user-layout {
  width: 100%;
  background-color: transparent;
  color: #ddd;
  font-size: 12px;
  z-index: 2;
}
